import { type ForcedState, type FormControllerAPI } from '@wix/form-viewer';
import { FormKind, initFormController } from '@wix/form-viewer/controller';
import { createEventHandler } from '@wix/tpa-settings';
import {
  type ControllerFlowAPI,
  type ControllerParams,
  type CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../constants/experiments';
import { NAMESPACE } from '../../constants/namespace';
import {
  SettingsEventsKeys,
  type SettingsEvents,
} from '../../constants/settings';
import { FORM_TEMPLATES, FormAppPreset } from '../../constants/templates';
import {
  type AIAssistantService,
  type AIAssistantState,
} from './ai-assistant-types';
import settingsParams from './settingsParams';
import { createFireSubmitTrackEvent } from './utils/submission-track-event';
import { isTemplateForm } from './utils/templates';
import { createVeloApi } from './velo-api';

type ControllerConfig = ControllerParams['controllerConfig'];

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  let formControllerApi: FormControllerAPI;
  let aiAssistantService: AIAssistantService;
  const { veloProps, veloApi, veloSetSteps } = createVeloApi(controllerConfig);
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerConfig.config.publicData.COMPONENT || {},
  );

  const aiAssistantEnabled = flowAPI.experiments.enabled(
    EXPERIMENTS.AI_ASSISTANT,
  );

  let formId = flowAPI.settings.get(settingsParams.formId) as string;

  if (formId) {
    [formControllerApi, aiAssistantService] = await Promise.all([
      initFormController(flowAPI, {
        formId,
        formKind: isTemplateForm(formId) ? FormKind.EXTENSION : undefined,
        namespace: NAMESPACE,
        throwWhenFormMissing: false,
      }),
      aiAssistantEnabled
        ? initAiAssistantService(flowAPI, controllerConfig)
        : undefined,
    ]);

    veloSetSteps(formControllerApi.getFormSteps(formId));
  }

  return {
    pageReady: async () => {
      if (aiAssistantEnabled && aiAssistantService) {
        aiAssistantService.setFormId(formId);
      }

      controllerConfig.setProps({
        fitToContentHeight: true,
        loading: true,
      });

      try {
        const showEmptyState = await getShowEmptyState(
          formId,
          flowAPI,
          controllerConfig,
        );

        if (showEmptyState) {
          controllerConfig.setProps({
            fitToContentHeight: true,
            loading: false,
          });
        } else {
          if (formId && formControllerApi) {
            if (formControllerApi.hasForm(formId)) {
              controllerConfig.setProps({
                formId,
                loading: false,
                fireSubmitTrackEvent: createFireSubmitTrackEvent(
                  formId,
                  formControllerApi.getFormName(formId),
                  formControllerApi.getFieldPropertiesByTarget(formId),
                  controllerConfig,
                ),
                aiAssistantService,
                ...veloProps,
              });
            } else {
              controllerConfig.setProps({
                loading: false,
                formDeleted: true,
              });
            }
          }
        }
      } catch (e) {
        // if error was encountered, set loading false, widget should show empty state
        console.log(e);
        controllerConfig.setProps({
          fitToContentHeight: true,
          loading: false,
        });
      }

      componentEventHandler.on(
        SettingsEventsKeys.ForceView,
        (forceView: ForcedState) =>
          controllerConfig.setProps({
            forceView,
          }),
      );
    },
    updateConfig: async (_, updatedConfig) => {
      const updatedFormId = updatedConfig?.publicData?.COMPONENT?.formId;
      const templateForm = isTemplateForm(updatedFormId);

      componentEventHandler.notify(updatedConfig.publicData.COMPONENT || {});

      if (!updatedFormId) {
        return;
      }

      if (formId !== updatedFormId) {
        controllerConfig.setProps({
          fitToContentHeight: true,
          loading: true,
        });

        const { getFormSteps } = await initFormController(flowAPI, {
          formId: updatedFormId,
          formKind: templateForm ? FormKind.EXTENSION : undefined,
          namespace: NAMESPACE,
        });

        veloSetSteps(getFormSteps(updatedFormId));
      }

      controllerConfig.setProps({
        fitToContentHeight: true,
        formId: updatedFormId,
        loading: false,
      });

      formId = updatedFormId;
    },
    exports: () => veloApi,
  };
};

function initAiAssistantService(
  flowAPI: ControllerFlowAPI,
  controllerConfig: ControllerConfig,
) {
  const updateFormAssistantStateProp = (state: AIAssistantState) => {
    controllerConfig.setProps({ aiAssistantState: state });
  };

  return import('./ai-assistant-service').then((module) =>
    module.createAIAssistantService({
      httpClient: flowAPI.httpClient,
      updateFormAssistantStateProp,
    }),
  );
}

async function getShowEmptyState(
  formId: string,
  flowAPI: ControllerFlowAPI,
  controllerConfig: ControllerConfig,
) {
  const shouldCheckIfFormLimitReached = !(
    flowAPI.environment.isViewer || flowAPI.environment.isPreview
  );

  const formLimitReached = shouldCheckIfFormLimitReached
    ? await import('./form-limit').then(({ isFormsLimitReached }) =>
        isFormsLimitReached(flowAPI.httpClient),
      )
    : false;

  const presetId = controllerConfig.config.publicData.COMPONENT.presetId;
  const newlyAddedTemplateForm =
    Object.keys(FORM_TEMPLATES).includes(presetId) && formId === '';

  const shouldShowEmptyState =
    ([FormAppPreset.Blank, FormAppPreset.Existing, undefined].includes(
      presetId,
    ) &&
      formId === '') ||
    (newlyAddedTemplateForm && formLimitReached);

  return shouldShowEmptyState;
}

export default createController;
